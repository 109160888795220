import React from 'react'
import { Container, Row } from 'reactstrap'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import * as Routes from 'routes'

import HR from 'components/HR'
import Arrow from 'components/Arrow'

import Wrapper from './Wrapper'
import Slogan from './Slogan'
import Nav, { NavLink } from './Nav'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        logotype: file(relativePath: { eq: "logotype.png" }) {
          childImageSharp {
            fixed(width: 71, height: 48) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Container className="text-center text-md-left">
          <Row>
            <Slogan className="col-12 col-md-4">
              <Img
                fixed={data.logotype.childImageSharp.fixed}
                className="mb-2"
                alt="Karer Media"
              />
              <br />
              <strong>Let's</strong> build
              <br />
              something <strong>great</strong>
            </Slogan>
          </Row>
          <HR />
          <Row>
            <div className="col-12 col-lg-auto">
              Copyright &copy; 2017-{new Date().getFullYear()} Karer Media. All
              rights reserved.
            </div>
            <div className="col-12 col-lg-auto ml-lg-auto">
              <a href="#" className="white">
                go to the top &nbsp;
                <Arrow up />
              </a>
            </div>
          </Row>
        </Container>
      </Wrapper>
    )}
  />
)

export default Footer
