import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import { Collapse, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'

import * as Routes from 'routes'

import Logo from 'components/Logo'
import NavLink from 'components/NavLink'
import {
  Container as DropdownContainer,
  Menu as DropdownMenu
} from 'components/NavigationDropdown'
import Arrow from 'components/Arrow'

import Navbar from './Navbar'
// import Language from './Language'

class Navigation extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  render () {
    const { isOpen } = this.state

    return (
      <Navbar light expand="md">
        <NavbarBrand tag={Link} to={Routes.HOME}>
          <Logo />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto align-items-center" navbar>
            <NavItem>
              <NavLink
                tag={Link}
                to={Routes.HOME}
                title="Home"
                activeClassName="active"
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="#o-nas"
                title="About us"
                activeClassName="active"
                onClick={e => {
                  const element = document.getElementById('o-nas')

                  if (element) {
                    e.preventDefault()

                    element.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
              >
                About us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="#contact"
                title="Get in touch"
                activeClassName="active"
              >
                Get in touch
              </NavLink>
            </NavItem>
            {/* <Language /> */}
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

export default Navigation
